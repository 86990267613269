<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/admin/user"
    entity-name="成员"
    hide-edit
    hide-delete
    :add-btn="false"
    :custom-url="customUrl"
    :action="action"
    :columns="columns"
    :action-width="80"
    @detail="showDetail">
    <template v-slot:query="{form}">
      <a-form-model-item label="手机号码">
        <a-select
            style="width: 180px"
            allowClear
            placeholder="选择完善状态筛选"
            @change="getList"
            :options="mobileOptions"
            v-model="form.mobile">
        </a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "WeChatUser",
  props: {
    dept: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      columns: [
        {
          title: '法名/姓名',
          dataIndex: 'wx_user_id',
          width: 120,
          customRender: (text) => <open-data type="userName" openid={text}/>
        },
        {
          title: '部门',
          dataIndex: 'dept_id',
          customRender: (text, record) => text ?
            <a-space>
              {
                text.split(",").map(id => <a-tag color={record.dept_main == id ? 'green' : ''}>
                  <open-data type="departmentName" openid={id}/>
                </a-tag>)
              }
            </a-space>
            : "--"
        },
        { title: '手机号码', dataIndex: 'mobile', width: 240,},
      ],
      mobileOptions: [
        { label: "未完善", value: 1 },
        { label: "已完善", value: 2 },
      ],
    }
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  methods: {
    showDetail({item}) {
      this.$router.push({name: 'HRBookDetail', params: {id: item.wx_user_id}})
    },
    customUrl(form) {
      let res = "";
      if (form.mobile === 1) {
        res += "&filter[mobile][is]=null";
      } else if (form.mobile === 2) {
        res += "&filter[mobile][neq]=null";
      }
      return res;
    },
    getList() {
      this.$refs.dataList.getList();
    }
  }
}
</script>

<style scoped lang="less">
.book-chart-card {
  background: transparent;
}
.book-chart-box {
  height: 200px;
}
</style>
